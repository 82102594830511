/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Rizki Nida Chaerulsyah
==============================================================================================*/
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700');
@import "sass-lib";

body {color: #333;font-size: 12px;font-family: 'Open Sans', sans-serif;position: relative;overflow-x: hidden;}
figure{
    margin: 0; padding: 0;
    img{vertical-align: middle;}
}
p {margin: 1px 0 15px; line-height: 20px;}
input, textarea, select {font-size: 12px;border: none;border: 1px solid #D8D8D8;padding: 10px;margin: 0;color: #666;  appearance: none;-webkit-appearance: none;-moz-appearance: none;}
input[type=checkbox], input[type=radio] {border-top-style: none;border-right-style: none;border-bottom-style: none;border-left-style: none;}
h1 { font-size: 60px; }
h2 { font-size: 48px; }
h3 { font-size: 24px; }
h4 { font-size: 20px; } 
h5 { font-size: 16px; } 
h6 { font-size: 14px; }
p  { font-size: 16px; }  

/* global
----------------------------------------------------------------------------------------------*/
.after_clear:after,.wrapper-large:after, .wrapper-wide:after, .wrapper-medium:after, .wrapper-small:after,section:after,.content-right:after,.content-left:after{clear:both;display: block;content:" "}
.before_clear:before{clear:both;display: block;content:" "}
.a-left{text-align: left}
.a-right{text-align: right}
.a-center , .text-center {text-align: center}
.text-grey {color: #ccc; }
.text-blue {color: #0083c3; }
.italic { font-style: italic; }
.bold { font-weight: bold; }
.regular { font-weight: lighter; }
.fl { float: left; }
.fr { float: right; }
.clear { clear: both; }

/* body
----------------------------------------------------------------------------------------------*/

.under{
    padding:75px;min-height:100vh;@include boxSizing(border-box);position:relative;background:url(../images/under/bg.jpg);color:#FFF;
    background-size:cover;
    .logo{
        text-align: center;margin-bottom:300px;
    }
    .text{
        position:absolute;text-align: center;right:100px;left:100px;height:200px;top:0;bottom:0;margin:auto;
        h6{font-size:16px;font-weight:bold;margin:0 0 30px 0;text-transform: uppercase;}
        h2{font-size: 61px;font-weight:300;margin:0 0 40px 0 ;}
        a.btn{
            font-size:13px;font-weight:bold;text-transform:uppercase;width: 290px;margin:auto;display: block;height:45px;line-height: 45px;
            color:#FFF;background:#2d499d;@include transition(0.15s ease-out all);@include borderRadius(30px);
            &:hover{background:#FFF;color:#2d499d;}
        }
    }
    .text-bottom{
        width:430px;position:absolute;text-align: center;right:0;left:0;bottom:70px;margin:auto;font-size:14px;line-height: 25px;
    }


}

@media (min-width: 0px) and (max-width:1680px){
    .under{
        .text{
            height:160px;
            h6{font-size:15px;margin-bottom:20px;}
            h2{font-size: 50px;margin-bottom: 30px}
        }
    }
}
@media (min-width: 0px) and (max-width:980px){
    .under{
        padding:50px;
        .logo img{width:200px;}
        .text{
            height:160px;left:50px;right:50px;
            h6{font-size:15px;margin-bottom:20px;}
            h2{font-size: 40px;margin-bottom: 30px}
        }
        .text-bottom{
            bottom: 30px;font-size:13px;line-height: 20px;
        }
    }
}
@media (min-width: 0px) and (max-width:680px){
    .under{
        padding:40px 20px;
        .logo{
            margin-bottom:350px;
            img{width:200px;}
        }
        .text{
            height:160px;position:absolute;left:20px;right:20px;
            h6{font-size:13px;margin-bottom:10px;}
            h2{font-size: 26px;margin-bottom: 15px;line-height: 34px}
            a.btn{width:260px;height:40px;line-height: 40px}
        }
        .text-bottom{
            bottom: 20px;font-size:12px;width:auto;padding:0 20px;right:0;left:0;max-width:430px;
        }
    }
}